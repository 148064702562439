import React from 'react'
import { DotButton, useDotButton } from './CarouselDotButtons'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './CarouselArrowButtons.js'
import useEmblaCarousel from 'embla-carousel-react'
import { BASE_URL } from './../../base'

const CategoryCarousel = ({
  lang,
  categories,
  selectedCategory,
  selectCategory,
  options,
  ...props
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)
  console.log('selectedCategory', selectedCategory)
  return (
    <section className='embla'>
      <div className='embla__viewport' ref={emblaRef}>
        <div className='embla__container'>
          {categories.map((category, index) => (
            <div
              onClick={() => selectCategory(category.id)}
              className={
                selectedCategory === category.id
                  ? 'slider slider-active'
                  : 'slider'
              }
            >
              <img
                src={`${BASE_URL}/upload/${category.image}`}
                alt='category'
              />
              <p style={{ textAlign: 'center' }}>
                {lang === 'en'
                  ? category.name
                  : lang === 'gr'
                  ? category.name_de
                  : category.name_a}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className='embla__controls'>
        <div className='embla__buttons'>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  )
}

export default CategoryCarousel
