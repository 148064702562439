import React, { Component } from 'react'
import SingleProduct from '../../components/Products/SingleProduct'
import Auth from '../../modules/Auth'
import LoginRegister from '../../components/LoginRegisterModal'
import { toast } from 'react-toastify'
import './Category.css'
import ProductService from '../../axios/ProductService'
import CategoryCarousel from './CategoryCarousel'
import './embla.css'
class Category extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: this.props.categories,
      products: this.props.products,
      filteredProducts: this.props.products,
      currency: props.currency.currency,
      modalShow: false,
      selectedCategory: '',
      login: true,
      active: -1,
      colors: []
    }
    this.searchCats = this.searchCats.bind(this)
    this.addToBag = this.addToBag.bind(this)
    this.filterByCategory = this.filterByCategory.bind(this)
    this.getColors = this.getColors.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
  }
  componentDidMount () {
    this.getColors()
    if (!this.props.categories) {
      this.props.getAllCategories()
    }
    if (!this.props.products) {
      this.props.getAllProducts()
    }
  }

  selectCategory = _category => {
    this.setState({
      filteredProducts: []
    })
    this.setState({ selectedCategory: _category })
    if (!!_category) {
      ProductService.getByCategory(_category).then(response => {
        this.setState({
          filteredProducts: response
        })
      })
    } else {
      this.props.getAllProducts()
    }
  }
  getColors = () => {
    ProductService.getColors().then(resp => {
      this.setState({ colors: resp.data })
    })
  }
  showHideModal = () => {
    this.setState({ modalShow: false })
  }

  loginClicked = () => {
    this.setState({ modalShow: true, login: true })
  }
  registerClicked = () => {
    this.setState({ modalShow: true, login: false })
  }

  addToBag = (id, count, color, price, image, name) => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      if (color == '')
        this.props.lang.lang === 'en'
          ? toast.error('you must select color')
          : this.props.lang.lang === 'gr'
          ? toast.error('Sie müssen eine Farbe auswählen')
          : toast.error('يجب تحديد لون المنتج الذي تريده')
      else {
        if (count > 0) {
          let cart = this.props.postCart(id, count, color, price, image, name)
          this.props.lang.lang === 'en'
            ? toast.success('Product added to cart successfully')
            : this.props.lang.lang === 'gr'
            ? toast.success('Produkt erfolgreich zum Warenkorb hinzugefügt')
            : toast.success('تم إضافة المنتج بنجاح إلى السلة')
        } else {
          toast.error(
            this.props.lang.lang === 'en'
              ? 'Quantity must be greater than 0'
              : this.props.lang.lang === 'gr'
              ? 'Die Menge muss größer als 0 sein.'
              : 'يجب أن تكون الكمية أكبر من 0'
          )
        }
      }
    } else {
      this.setState({ modalShow: true })
    }
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.products != this.props.products)
      this.setState({
        products: this.props.products,
        filteredProducts: this.props.products
      })
  }
  filterByCategory (id) {
    if (id !== 0)
      this.setState({
        filteredProducts: this.state.products.filter(e => e.category_id == id),
        active: id
      })
    else
      this.setState({
        filteredProducts: this.state.products,
        active: id
      })
  }
  searchCats = e => {
    let _products = this.state.products
    let _val = !!e.target.value ? e.target.value.toLowerCase() : ''
    console.log('e.target', _products)
    if (!!_val && !!this.state.active && this.state.active !== -1) {
      console.log('Hello1')
      _products = _products.filter(
        e =>
          e.category_id === this.state.active &&
          (e.ar_name.toLowerCase().includes(_val) ||
            e.en_name.toLowerCase().includes(_val) ||
            e.de_name.toLowerCase().includes(_val))
      )
    } else if (!!this.state.active && this.state.active !== -1) {
      _products = _products.filter(e => e.category_id === this.state.active)
    } else if (!!_val) {
      _products = _products.filter(
        e =>
          e.ar_name.toLowerCase().includes(_val) ||
          e.en_name.toLowerCase().includes(_val) ||
          e.de_name.toLowerCase().includes(_val)
      )
    }
    this.setState({
      filteredProducts: _products
    })
  }

  OPTIONS = { slidesToScroll: 'auto' }
  SLIDE_COUNT = 3
  SLIDES = Array.from(Array(this.SLIDE_COUNT).keys())

  render () {
    const { categories } = this.props
    return (
      <div className='container product_section_container'>
        <div className='row'>
          <div className='col product_section clearfix'>
            <div className='main_content'>
              <div class='products_iso'>
                <div
                  className='row my-3'
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <input
                    onChange={e => this.searchCats(e)}
                    className='form-control w-75'
                    placeholder={
                      this.props.lang.lang === 'en'
                        ? 'Search Products....'
                        : this.props.lang.lang === 'gr'
                        ? 'Produkte suchen....'
                        : 'بحث عن منتج...'
                    }
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? { direction: 'ltr', backgroundColor: 'beige' }
                        : { direction: 'rtl', backgroundColor: 'beige' }
                    }
                  />
                </div>
                {categories != null && categories.length > 0 && (
                  <div
                    class='row category-slider'
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <CategoryCarousel
                      categories={categories}
                      lang={this.props.lang.lang}
                      selectCategory={this.selectCategory}
                      selectedCategory={this.state.selectedCategory}
                      options={this.OPTIONS}
                      slides={this.SLIDES}
                    />
                  </div>
                )}
                <div className='row'>
                  {this.state.filteredProducts &&
                    this.state.colors.length > 0 &&
                    this.state.filteredProducts.map((item, index) => {
                      return (
                        <div
                          className='col-lg-3 col-sm-6'
                          key={index}
                          style={{ margin: '', padding: '5px' }}
                          data-aos='zoom-in'
                        >
                          <SingleProduct
                            currency={this.props.currency.currency}
                            lang={this.props.lang.lang}
                            productItem={item}
                            addToBag={this.addToBag}
                            colors={this.state.colors}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    )
  }
}

export default Category
