import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userLogin } from '../../redux/actions/LoginAction'
import Validator from '../../utils/Validator'
import { DEFAULT_RULE, EMAIL_RULE } from '../../utils/Validator/rule'
import PropTypes from 'prop-types'
import LoadingButton from '../LoadingButton'
import LoginService from './../../axios/LoginService'
import { toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import Auth from '../../modules/Auth'
import jumpTo from '../../modules/Navigation'

class LoginForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      code: '',
      requireCode: false,
      loadingCode: false,
      loading: false,
      loading1: false,
      loading2: false
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  LoginType = type => {
    if (type == 'google') {
      this.setState({ loading2: true })
    } else {
      this.setState({ loading1: true })
    }
    LoginService.LoginType(type)
      .then(res => {
        console.log(res)
        if (type == 'google') {
          this.setState({ loading2: false })
        } else {
          this.setState({ loading1: false })
        }
        var newWindow = window.open()
        newWindow.document.write(res)
      })
      .catch(error => {
        if (type == 'google') {
          this.setState({ loading2: false })
        } else {
          this.setState({ loading1: false })
        }
      })
  }

  handleSubmit = () => {
    const { email, password } = this.state
    if (!Validator(email, EMAIL_RULE)) {
      console.log('email Error')
      return
    }
    if (!Validator(password, DEFAULT_RULE)) {
      console.log('Password Error')
      return
    }
    this.setState({ loading: true })
    this.props
      .userLogin(email, password)
      .then(res => {
        this.setState({ loading: false })
        if (
          JSON.parse(localStorage.getItem('loginError'))?.message ==
          'Your account is not verified.'
        ) {
          this.setState({ loading: false, code: '', requireCode: true })
        }
        if (!!JSON.parse(localStorage.getItem('auth')).token) {
          localStorage.removeItem('cart')
          window.location.reload()
        }
      })
      .catch(error => {
        this.setState({ loading: false })
      })
  }
  handleSubmitCode = () => {
    this.setState({ loadingCode: true })
    const { email, code } = this.state
    if (!Validator(code, DEFAULT_RULE)) {
      return
    }
    if (!Validator(email, EMAIL_RULE)) {
      return
    }
    LoginService.verifyCode(email, code)
      .then(res => {
        this.setState({ loadingCode: false })
        if (
          JSON.parse(localStorage.getItem('loginError')).message ==
          'code is false'
        ) {
          this.setState({ loading: false, code: '' })
        }
        if (res) {
          toast.success('Please Login again.')
          localStorage.removeItem('loginError')
          this.setState({ loadingCode: false, code: '', requireCode: false })
        }
      })
      .catch(error => {})
  }

  handleSubmitGoogle = credentials => {
    if (!!credentials.credential) {
      let googleObj = jwtDecode(credentials.credential)
      console.log(googleObj)
      const new_user = {
        token: credentials.credential,
        user: googleObj
      }
      Auth.setGoogleToken(new_user)
      if (!!JSON.parse(localStorage.getItem('auth_google')).token) {
        jumpTo('/profile-google')
      }
    } else {
      this.props.lang === 'en'
        ? toast.error('An error occurred')
        : this.props.lang === 'gr'
        ? toast.error('Ein Fehler ist aufgetreten')
        : toast.error('حدث خطأ ما')
    }
  }
  render () {
    return (
      <div>
        <div className='login-form'>
          <h2>
            {this.props.lang === 'en'
              ? 'Login'
              : this.props.lang === 'gr'
              ? 'Anmelden'
              : 'تسجيل الدخول'}
          </h2>
          {this.state.requireCode ? (
            <>
              Please provide your email and verification code
              <div className='form-group '>
                <input
                  type='text'
                  className='form-control'
                  placeholder={
                    this.props.lang === 'en'
                      ? 'Email'
                      : this.props.lang === 'gr'
                      ? 'Email'
                      : 'الايميل الالكتروني'
                  }
                  id='UserName'
                  name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete='false'
                />
                <i className='fa fa-user'></i>
              </div>
              <div className='form-group log-status'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Code'
                  id='code'
                  name='code'
                  value={this.state.code}
                  onChange={this.handleChange}
                  autoComplete='false'
                />
              </div>
              <LoadingButton
                type='button'
                className='log-btn'
                loading={this.state.loadingCode}
                onClick={() => this.handleSubmitCode()}
              >
                Submit
              </LoadingButton>
            </>
          ) : (
            <>
              <div className='form-group '>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Email '
                  id='UserName'
                  name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete='false'
                />
                <i className='fa fa-user'></i>
              </div>
              <div className='form-group log-status'>
                <input
                  type='password'
                  className='form-control'
                  placeholder={
                    this.props.lang === 'en'
                      ? 'Password'
                      : this.props.lang === 'gr'
                      ? 'Passwort'
                      : 'كلمة المرور'
                  }
                  id='Passwod'
                  name='password'
                  value={this.state.password}
                  onChange={this.handleChange}
                  autoComplete='false'
                />
                <i className='fa fa-lock'></i>
              </div>
              <span className='alert'>Invalid Credentials</span>
              <LoadingButton
                type='button'
                className='log-btn'
                loading={this.state.loading}
                onClick={() => this.handleSubmit()}
              >
                {this.props.lang === 'en'
                  ? 'Login'
                  : this.props.lang === 'gr'
                  ? 'Anmelden'
                  : 'تسجيل الدخول'}
              </LoadingButton>
              <GoogleLogin
                onSuccess={credentials => {
                  console.log(credentials)
                  if (!!credentials.credential) {
                    this.handleSubmitGoogle(credentials)
                  }
                }}
                onError={error => console.log(error)}
              />
              <div
                onClick={this.props.registerClicked}
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  color: '#c4c4c4',
                  cursor: 'pointer'
                }}
              >
                {this.props.lang === 'en'
                  ? 'New user? Please Register'
                  : this.props.lang === 'gr'
                  ? 'Neuer Benutzer? Bitte registrieren Sie sich'
                  : 'مستخدم جديد؟ إضافة حساب'}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

LoginForm.propTypes = {
  forgotPasswordClicked: PropTypes.func,
  registerClicked: PropTypes.func,
  verifyClicked: PropTypes.func
}

const mapDispatchToProps = {
  userLogin
}
const mapStoreToProps = state => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error
})

export default connect(mapStoreToProps, mapDispatchToProps)(LoginForm)
