import React, { useState } from 'react'
import jumpTo from '../../modules/Navigation'
import { BASE_URL } from '../../base'
import { toast } from 'react-toastify'
import ProductService from '../../axios/ProductService'
import './Category.css'

function SingleProduct (props) {
  const { productItem } = props
  const [color, setColor] = useState(JSON.parse(productItem.color)[0])
  const [qty, setQty] = useState(0)
  const addToCart = (id, price, image, name) => {
    if (qty === 0 || color === '') {
      let message =
        props.lang === 'en'
          ? 'Quantity must be greater than 0'
          : props.lang === 'gr'
          ? 'Die Menge muss größer als 0 sein.'
          : 'يجب أن تكون الكمية أكبر من 0'
      toast.error(message)
      return
    }
    props.addToBag(id, qty, color, price, image, name)
  }
  const onAddClicked = () => {
    if (qty + 1 <= productItem.entity) setQty(qty + 1)
  }
  const onRemoveClicked = () => {
    if (qty - 1 <= productItem.entity) setQty(qty - 1)
  }
  const [img, setImg] = useState(productItem?.image)
  const [colors, setColors] = useState(props.colors)
  React.useEffect(() => {
    if (productItem && productItem.image) setImg(productItem.image)
  }, [productItem])

  return (
    productItem && (
      <div className='product-item men'>
        <div
          className='product discount product_filter'
          onClick={() => jumpTo(`/single-product/${productItem.id}`)}
        >
          <div className='product_image'>
            {img && (
              <img
                key={img}
                src={`${BASE_URL}upload/${img}`}
                alt=''
                className='img-fluid'
              />
            )}
          </div>
          <div className='favorite favorite_left'>
            <i className='far fa-heart' />
          </div>
          <div className='product_info'>
            <h6 className='product_name'>
              <div>
                {props.lang === 'en'
                  ? productItem.en_name
                  : props.lang === 'gr'
                  ? productItem.de_name
                  : productItem.ar_name}
              </div>
            </h6>
            <div className='product_color' style={{ height: '2em' }}>
              <span style={{ fontWeight: 'bold' }}>
                {props.lang === 'en'
                  ? `colors:`
                  : props.lang === 'gr'
                  ? 'Farben:'
                  : ''}
              </span>
              {
                <>
                  <ul style={{ marginLeft: '0px' }}>
                    {JSON.parse(productItem.color) &&
                      JSON.parse(productItem.color).map(e => (
                        <li
                          style={{
                            background: `${e}`,
                            border: '1px solid black',
                            marginRight: '1px',
                            zIndex: '999'
                          }}
                        ></li>
                      ))}
                  </ul>
                </>
              }
            </div>

            <div className='product_price mt-n3'>
              {productItem.selling_price && (
                <span
                  style={{ textDecoration: 'line-through', margin: '0 1em' }}
                >
                  {productItem.selling_price} €
                </span>
              )}
              {`${productItem.unit_price} €`}
            </div>
          </div>
        </div>
        <div className='add_to_cart_button' style={{ height: '5em' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-3em'
            }}
          >
            <select
              onChange={event => {
                let colors = JSON.parse(productItem.color)
                let index =
                  colors && colors.length > 0
                    ? colors.findIndex(c => c === event.target.value)
                    : 0
                index > 0
                  ? setImg(productItem['image_' + ++index])
                  : setImg(productItem['image'])
                setColor(event.target.value)
              }}
              value={color}
              className='form-control select mx-1 my-3 w-75'
            >
              {productItem.color &&
                JSON.parse(productItem.color).map((e, index) => (
                  <option
                    style={{
                      backgroundColor: e,
                      color: 'black',
                      border: '1px solid black'
                    }}
                    key={index}
                    value={e}
                  >
                    {
                      colors.filter(c => {
                        return c.code === e
                      })[0]?.name
                    }
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div
          className='quantity d-flex flex-column flex-sm-row align-items-sm-center'
          style={
            props.lang === 'en' || props.lang === 'gr'
              ? { direction: 'ltr' }
              : { direction: 'rtl' }
          }
        >
          <span
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px'
            }}
          >
            {props.lang === 'en'
              ? `All(${productItem.entity})`
              : props.lang === 'gr'
              ? `Alle (${productItem.entity})`
              : `العدد الكلي (${productItem.entity})`}
          </span>
          <div className='quantity_selector'>
            <span
              className={qty > 1 ? 'minus' : 'minus disabled'}
              onClick={() => onRemoveClicked()}
            >
              <i className='fa fa-minus' aria-hidden='true'></i>
            </span>
            <span id='quantity_value'>{qty}</span>
            <span className='plus' onClick={() => onAddClicked()}>
              <i className='fa fa-plus' aria-hidden='true'></i>
            </span>
          </div>
        </div>
        <div
          className='red_button add_to_cart_button'
          onClick={() =>
            addToCart(
              productItem.id,
              productItem.selling_price,
              `${BASE_URL}upload/${img}`,
              productItem.en_name
            )
          }
        >
          <div style={{ color: '#ffffff' }}>
            {props.lang === 'en'
              ? `add`
              : props.lang === 'gr'
              ? 'in den Warenkorb legen'
              : `إضافة`}
          </div>
        </div>
      </div>
    )
  )
}

export default SingleProduct
